import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './app.css';
const apiKey = process.env.REACT_APP_FORM_CREATOR_API_KEY;

function App() {
  const [csvFile, setCsvFile] = useState(null);
  const [shortenedData, setShortenedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processingMessage, setProcessingMessage] = useState('');
  useEffect(() => {
    document.title = "Outburst Upload CSV to Shorten URL - beta";
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
  };

  const convertCsvToJson = (csvText) => {
    const lines = csvText.split('\n');
    const jsonData = [];
    const headers = lines[0].split(',').map(header => header.trim());
    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(',');
      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = data[j] ? data[j].trim() : null;
      }
      jsonData.push(entry);
    }
    return jsonData;
  };

  const convertJsonToCsv = (jsonData) => {
    const headers = Object.keys(jsonData[0]);
    const csvLines = [headers.join(',')];
    for (let i = 0; i < jsonData.length; i++) {
      const values = headers.map(header => jsonData[i][header]);
      csvLines.push(values.join(','));
    }
    return csvLines.join('\n');
  };

  const splitArrayIntoChunks = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleUpload = async () => {
    if (!csvFile) return;

    setLoading(true);
    setError(null);
    setShortenedData(null);
    setProcessingMessage('Uploading and processing data...');

    try {
      const reader = new FileReader();
      reader.onload = async function(event) {
        const csvText = event.target.result;
        const jsonData = convertCsvToJson(csvText);
        const chunkedData = splitArrayIntoChunks(jsonData, 250);

        const responses = await Promise.all(chunkedData.map(async (chunk) => {
          const response = await axios.post('https://shortlink-creator.carl-aa3.workers.dev/links', { urls: chunk }, {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey,
            }
          });
          return response.data;
        }));

        const mergedData = [].concat(...responses);
        const updatedJsonData = mergedData.map((item, index) => ({ ...jsonData[index], shortened: item.shortened }));
        const csvData = convertJsonToCsv(updatedJsonData);
        setShortenedData(csvData);
        setProcessingMessage('');
      };
      reader.readAsText(csvFile);
    } catch (error) {
      setError('Error uploading file. Please try again.');
      console.error('Error uploading file:', error);
      setProcessingMessage('');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const blob = new Blob([shortenedData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'shortened_data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="container">
      <h1 className="title">Outburst Upload CSV to Shorten URL - beta </h1>
      <h2>Expected CSV Format:</h2>
    <table className="csv-format-table">
      <thead>
        <tr>
          <th>url</th>
          <th>domain</th>
          <th>phone</th>
          <th>email</th>
          <th>clientId</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>https://example.com/test1</td>
          <td>https://red-txt.com</td>
          <td>239-867-5309</td>
          <td>test@test.com</td>
          <td>test-org</td>
        </tr>
        <tr>
          <td>https://example.com/test2</td>
          <td>https://ameri24.com</td>
          <td>239-867-5309</td>
          <td>test2@test.com</td>
          <td>test-org</td>
        </tr>
      </tbody>
    </table>
    <div className="uploadarea">
      <input type="file" onChange={handleFileChange} className="file-input" />
      <button onClick={handleUpload} disabled={loading} className="upload-button">
        {loading ? 'Uploading...' : 'Upload CSV'}
      </button>

      {processingMessage && <p className="processing-message">{processingMessage}</p>}
      {error && <p className="error-message">{error}</p>}

      {shortenedData && (
        <div>
          <h2 className="data-header">Shortened CSV Data</h2>
          <button onClick={handleDownload} className="download-button">Download CSV</button>
        </div>
      )}
    </div>
    </div>
  );
}

export default App;